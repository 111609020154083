<template>
    <div>
        <headTitle/>
        <mobileCarousel style="margin-top:12rem"/>
        <div class="mobile-title" style="margin-top:3.7rem">行业方案</div>
        <div class="mobile-content">比孚聚焦于快消零售,医疗健康两大行业</div>
        <div class="mobile-index-card-1" style="margin-top:3.1rem">
            <img src="../../assets/image/index/01-1快消零售.png"/>
            <div class="title1">快消零售</div>
            <div class="title2">比孚与快消零售行业内众多优秀厂商共建长期稳定合
                作关系，基于多年的行业咨询和实施经验，积累了完整
                的数智供应链解决方案，帮助合作伙伴建立标准化管理
                体系和流程，优化供应链组织结构，为业务转型提供全
                面支持。
            </div>
            <div class="title3" @click="jumpMobileRoute('/mobile-programme-sale')">了解详情</div>
        </div>
        <div class="mobile-index-card-1" style="margin-top:2rem">
            <img src="../../assets/image/index/01-1医疗健康.png"/>
            <div class="title1">医疗健康</div>
            <div class="title2">比孚与IQVIA展开深度合作，形成了全方位战略合作
                共赢模式，凭借医药生物行业内成熟的产品及解决方案，帮助企业完成实
                施及落地，从而加速实现业务转型和获取价值。
            </div>
            <div class="title3" @click="jumpMobileRoute('/mobile-programme-medical')">了解详情</div>
        </div>
        <div class="mobile-title" style="margin-top:3.7rem">产品方案</div>
        <div class="mobile-index-card-1" style="height: auto;padding-bottom: 3rem;margin-top:3.1rem">
            <img src="../../assets/image/mobile/mobile-index-Vticket.png"/>
            <div class="title-image">
              <img class="box-content-title-img" src="@/assets/image/index/vticket.svg" alt="">
            </div>
            <div class="title2" style="height: auto;padding-bottom: 3rem;">Vticket 是一款“不一样的”IT服务管理（ITSM）平台。包含20余项功能模块，融合了100多项创新功能，依托“以图传意，化繁为简”的核心理念，通过“可视化”、“协同化”和“智能化”，给用户带来轻松的IT服务体验的同时，提高IT团队的工作效率，持续巩固和改进企业数字化的成果。</div>
            <div class="title3" @click="handleJumpTo('vticket')">了解详情</div>
        </div>
        <div class="mobile-index-card-1" style="height: auto;padding-bottom: 3rem;margin-top:2rem">
            <img src="../../assets/image/mobile/mobile-index-Vproject.png"/>
            <div class="title-image">
              <img class="box-content-title-img" src="@/assets/image/index/vproject.svg" alt="">
            </div>
            <div class="title2" style="height: auto;padding-bottom: 3rem;">Vproject 是一款“更聪明的”IT项目管理平台。最大亮点在于其高度的定制化和灵活性。无论是小型团队还是大型企业，Vproject都能适应各种需求，提供个性化的工作流程和视图，包括看板、甘特图、列表视图等。此外，Vproject的AI智能助理和自动化功能，进一步简化了日常任务和流程管理，使项目管理变得更加智能和高效。</div>
            <div class="title3" @click="handleJumpTo('vproject')">了解详情</div>
        </div>
        <div class="mobile-title" style="margin-top:9rem">精选案例</div>
        <div class="mobile-content">帮助企业以更低成本更好效果更短时间内实现业务流程的数字 转型，提升业务效率，减少人工，优化成本</div>
        <div class="mobile-carousel-2">
          <div class="product-case-tabs">
            <div
                v-for="(item, index) in productCaseTabs"
                class="product-case-tab-item"
                :class="item.value === productCaseTabValue ? 'active' : ''"
                :key="index"
                @click="productCaseTabValue = item.value"
              >
              {{ item.label }}
            </div>
          </div>
          <div class="swiper-container product-swiper-container-1" id="banner" v-show="productCaseTabValue === 'productBoutique'">
            <div class="swiper-wrapper" id="banner-list">
              <div class="swiper-slide" v-for="item in box2ImageList" :key="item.index">
                <div class="img-item" @click="jumpMobileRoute(item.route)">
                  <div class="img-container">
                    <img :src="item.urlImg" />
                  </div>

                  <div class="img-item-title">{{item.title}}</div>
                  <div class="img-item-content-box"><div class="img-item-content">{{item.content}}</div></div>
                  <div class="img-item-more">查看全部</div>
                </div>
              </div>
            </div>
            <!-- <div class="swiper-button-prev"  @click="next"><div class="swiper-line-left"></div></div>
            <div class="swiper-button-next"  @click="prev"><div class="swiper-line-right"></div></div> -->
          </div>

          <div class="swiper-container product-swiper-container-2" id="banner2" v-show="productCaseTabValue === 'productCase'">
            <div class="swiper-wrapper" id="banner-list2">
              <div class="swiper-slide" v-for="item in productCaseImage" :key="item.index">
                <div class="img-item" @click="jumpMobileRoute(item.route)">
                  <div class="img-container">
                    <img :src="item.urlImg" />
                  </div>

                  <div class="img-item-title">{{item.title}}</div>
                  <div class="img-item-content-box"><div class="img-item-content">{{item.content}}</div></div>
                  <div class="img-item-more">查看全部</div>
                </div>
              </div>
            </div>
            <!-- <div class="swiper-button-prev"  @click="next2"><div class="swiper-line-left"></div></div>
            <div class="swiper-button-next"  @click="prev2"><div class="swiper-line-right"></div></div> -->
          </div>
        </div>
        <div class="mobile-title" style="margin-top:4rem">技术服务</div>
        <div class="mobile-content">提供从咨询设计到开发落地运维的<br/>完整技术服务</div>
        <div class="mobile-index-card-2" style="margin-top:3.1rem">
            <img src="../../assets/image/mobile/01-1数据湖仓技术.png"/>
            <div class="title1">数据湖仓技术</div>
            <div class="title2">比孚科技作为Amazon高级咨询合作伙伴，致力于帮
                助企业构建数据湖，实现核心流程数字化、自助式商业智能、BI移动化
                等服务，推动企业数字化转型。
            </div>
            <div class="title3" @click="jumpMobileRoute('/mobile-service-lake')">了解详情</div>
        </div>
        <div class="mobile-index-card-2" style="margin-top:3.1rem">
            <img src="../../assets/image/mobile/01-1数据湖仓技术备份.png"/>
            <div class="title1">SAP实施及运维</div>
            <div class="title2">作为SAP全球认证的合作伙伴，比孚凭借着对信息技
                术趋势的洞察，丰富的行业经验，以及全方位的服务，协助客户进行数
                字化转型、云部署和技术创新。
            </div>
            <div class="title3" @click="gotoOutLine">了解详情</div>
        </div>
        <div class="mobile-index-card-2" style="margin-top:3.1rem">
            <img src="../../assets/image/mobile/01-1数据湖仓技术备份2.png"/>
            <div class="title1">定制开发及运维</div>
            <div class="title2">比孚科技凭精湛的研发技术，以及在制造，零售，医
                药行业数字化转型上积累的丰富经验， 可为客户提供各类业务系统的定
                制化开发服务。
            </div>
            <div class="title3" @click="jumpMobileRoute('/mobile-service-customized')">了解详情</div>
        </div>
        <div class="mobile-index-card-2" style="height: auto;padding-bottom: 3rem;margin-top:3.1rem">
            <img src="../../assets/image/mobile/mobile-index-technology-service.png.png"/>
            <div class="title1">IT 服务平台</div>
            <div class="title2" style="height: auto;padding-bottom: 3rem;">画简信息科技致力于为企业和个人提供简单、高效、实用的IT服务及项目研发管理解决方案，帮助客户在数字化时代中快速响应市场变化，实现业务价值的最大化。</div>
            <div class="title3" @click="jumpMobileRoute('/mobile-service-system')">了解详情</div>
        </div>
        <div class="mobile-title" style="margin-top:4rem">新闻资讯</div>
        <el-carousel indicator-position="outside" arrow="never" height="30rem">
            <el-carousel-item v-for="item in box4List" :key="item.index">
                <div class="mobile-carousel-4">
                    <img :src="item.url">
                    <div class="box4">
                        <div class="title-box">
                            <div class="title">{{item.title}}</div>
                            <div class="date">{{item.date}}</div>
                        </div>
                        <div class="more" @click="jumpMobileRoute(item.route)">阅读更多<i class="el-icon-right" style="margin-top:0.8rem"></i></div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="mobile-title" style="margin-top:6rem">合作伙伴</div>
        <div class="mobile-left-title" style="margin-top:2rem">客户摘选</div>
        <div class="mobile-index-container1" style="margin-top:2rem">
            <img src="../../assets/image/mobile/01-1可乐.png"/>
            <img src="../../assets/image/mobile/01-1IQVIA.png"/>
            <img src="../../assets/image/mobile/01-1上海电气.png"/>
            <img src="../../assets/image/mobile/01-1联合利华.png"/>
            <img @click="jumpMobileRoute('/mobile-aboutus-index')" src="../../assets/image/mobile/01-1更多客户.png"/>
        </div>
        <div class="mobile-left-title" style="margin-top:4rem">合作伙伴</div>
        <div class="mobile-index-container1" style="margin-top:2rem">
            <img src="../../assets/image/mobile/01-1亚马逊云科技.png"/>
            <img src="../../assets/image/mobile/01-1微软.png"/>
            <img src="../../assets/image/mobile/01-1SAP.png"/>
            <img src="../../assets/image/mobile/01-1denodo.png"/>
            <img @click="jumpMobileRoute('/mobile-aboutus-index')" src="../../assets/image/mobile/01-1更多客户备份.png"/>
        </div>
        <div class="mobile-index-container2" style="height:35rem">
            <div class="mobile-title" style="margin-top:4rem;padding-top: 2rem;">联系我们</div>
            <div class="service-box">
                <div class="service-title3">让我们了解您的需求，给您量身定制解决方案!</div>
                <div class="service-title4" style="cursor:pointer" @click="jumpMobileRoute('/mobile-joinus-index')">查找最优化方案？ 现在联系我们<img style="margin-left:2rem" src="../../assets/image/index/右箭头.svg"/></div>
            </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import { getAction } from '@/utils/request.js';
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/mobile-case-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/mobile-case-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/mobile-case-permeability'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/mobile-case-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/mobile-case-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'中国某知名调味品生产公司',
                    route:'/mobile-case-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/mobile-case-iDAS'
                },

            ],
            box4List:[
                // {
                //     index: 1,
                //     title:'惊心动魄，可口可乐SCMC的“黄金24小时”',
                //     date:'2022/10/26',
                //     content:'随着疫情的常态化发展，近年来，越来越多企业选择将IT架构，重要应用、关键数据等核心业务迁移至云端，由于数据对企业发展有着至关重要的作用，因此在选择云平台时，会优先考虑云平台的安全性，提高业务运维的灵活性和弹性。一旦出现突发问题，而运维人员和关键组件无法第一时间到达到场，将会给企业业务带来极大风险。',
                //     url:require("../../assets/image/index/05-0可乐24小时.png"),
                //     route:'/mobile-news-detail1'
                // },
                // {
                //     index: 2,
                //     title:'第三个3年，比孚再次通过ISO9001认证',
                //     date:'2022/11/04',
                //     content:'2022年11月3日，上海比孚信息科技有限公司再一次顺利通过了ISO9001的认证。 ISO9001证书有效期为3年，从2014年开始，这已经是我们第三个3年了。 在此认证审核的过程中，我们自上而下，从公司，部门到项目，展示了各层级的整体目标及相应的质量标准，实施方案和成果。',
                //     url:require("../../assets/image/index/01-1第三个3年.png"),
                //     route:'/mobile-news-detail2'
                // },
                // {
                //     index: 3,
                //     title:'比孚科技和亚马逊云科技联合主办SAP上云探索及经验分享交流会',
                //     date:'2022/11/30',
                //     content:'2022年11月30日，比孚科技和亚马逊云科技将在上海地标建筑马勒别墅饭店，联合主办SAP上云探索及经验分享交流会。随着数字经济发展日新月异，企业转型面临多重挑战，云转型无疑成为未来发展的趋势，加上新冠疫情的影响，加速了企业上云的决策，是推进企业数字化转型的关键步伐……',
                //     url:require("../../assets/image/index/5-0SAP乘云而上.png"),
                //     route:'/mobile-news-detail3'
                // },
                // {
                //     index: 4,
                //     title:'“乘云而上—构建高度集成的一体化数字管理平台”研讨会顺利举办”',
                //     date:'2022/12/09',
                //     content:'2022年11月30日，由畅享网承办的“乘云而上——构建高度集成的一体化数字管理平台”在上海顺利举办。在尊重疫情防控要求的前提下，与会人员一同基于如何利用云服务的弹性、敏捷性、全球化部署等特点，探讨企业上云机遇与方案，助力企业数字化战略落地以及核心业务效能提升。',
                //     url:require("../../assets/image/index/05-4SAP乘云而上研讨会.png"),
                //     route:'/mobile-news-detail4'
                // },
                // {
                //     index: 5,
                //     title:'新起点，新征程 —— 可口可乐不含气业务系统顺利切换上线',
                //     date:'2023/01/01',
                //     content:'2023新年伊始，中粮与太古完成收购可口可乐中国不含气饮料业务。而就在前一天（即22年12月31日），在中粮及太古领导的大力支持下，上海比孚信息科技有限公司（简称比孚）作为项目的实施方，成功将相关业务系统从原先的可口可乐装瓶商（SCMC）顺利切换到中粮和太古可口可乐麾下。',
                //     url:require("../../assets/image/news/5-5.png"),
                //     route:'/mobile-news-detail5'
                // },
            ],
            productCaseTabs: [
              {
                label: '精选案例',
                value: 'productBoutique'
              },
              {
                label: '产品案例',
                value: 'productCase'
              },
            ],
            productCaseTabValue: 'productBoutique',
            productCaseImage: [
              {
                index: 0,
                urlImg: require("../../assets/image/mobile/mobile-product-case-index-technology.png"),
                title: '某知名传媒科技公司',
                content: '私有化部署，自动生成纪要及任务',
                route: '/mobile-produt-case-technology'
              },
              {
                index: 1,
                urlImg: require("../../assets/image/mobile/mobile-product-case-index-metallurgy.png"),
                title: '某大型国有冶金集团',
                content: '智能派单，识别关键用户和问题',
                route: '/mobile-produt-case-metallurgy'
              },
              {
                index: 2,
                urlImg: require("../../assets/image/mobile/mobile-product-case-index-pharmaceutical.png"),
                title: '某大型医药企业',
                content: '服务门户，自动化的运维服务流程',
                route: '/mobile-produt-case-pharmaceutical'
              },
              {
                index: 3,
                urlImg: require("../../assets/image/mobile/mobile-product-case-index-catering.png"),
                title: '某全国连锁餐饮企业',
                content: 'AI智能客服，7*24小时即时响应',
                route: '/mobile-produt-case-catering'
              },
              {
                index: 4,
                urlImg: require("../../assets/image/mobile/mobile-product-case-index-retail.png"),
                title: '某大型零售连锁企业',
                content: '远程协助，迅速定位并解决故障',
                route: '/mobile-produt-case-retail'
              },
            ]
        };
    },
    created() {
        // this.box4List.reverse()
    },
    mounted() {
        this.init()
        this.mySwiper = new Swiper(".product-swiper-container-1", {

            centeredSlides: true,

            // slidesPerView: 1.1, //数值越大，两侧显示的图越大

            // loop: true,

            autoplay: 3000,
            cache: false,
            effect : 'coverflow',
            slidesPerView: 3,
            initialSlide :3,//默认第四页
            centeredSlides: true,
            coverflow: {
                rotate: 30,
                stretch: 10,
                depth: 60,
                modifier: 2,
                slideShadows : true
            },
            // observer: true, // 改变swiper样式时，自动初始化swiper
            // observeParents: true, // 监测swiper父元素，如果有变化则初始化swiper
            spaceBetween : 10,
            // centeredSlidesBounds: true,
            slideDuplicateClass : 'my-slide-duplicate',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
            },

        })
        this.mySwiper2 = new Swiper(".product-swiper-container-2", {

centeredSlides: true,

// slidesPerView: 1.1, //数值越大，两侧显示的图越大

// loop: true,

autoplay: 3000,
cache: false,
effect : 'coverflow',
slidesPerView: 3,
initialSlide :3,//默认第四页
centeredSlides: true,
coverflow: {
    rotate: 30,
    stretch: 10,
    depth: 60,
    modifier: 2,
    slideShadows : true
},
// observer: true, // 改变swiper样式时，自动初始化swiper
// observeParents: true, // 监测swiper父元素，如果有变化则初始化swiper
spaceBetween : 10,
// centeredSlidesBounds: true,
slideDuplicateClass : 'my-slide-duplicate',
navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
},
on: {
},

})
    },

    methods: {
        async init(){
            const res = await getAction(`/wp/wp-json/wp/v2/posts?categories=6`);
            if(res){
                res.map((element,index) => {
                    let oneNew = {}
                    oneNew.index = index;
                    oneNew.title = element.title.rendered
                    let date = element.date.split('T')
                    let newDate = date[0].split('-')[0] + '/' + date[0].split('-')[1] + '/' + date[0].split('-')[2]
                    oneNew.date = newDate
                    oneNew.content = element.content.rendered.split('<p>')[1].split('</p>')[0]
                    oneNew.route = `/mobile-news-detail/${element.id}`
                    this.box4List.push(oneNew)
                    getAction(`/wp/wp-json/wp/v2/media/${element.featured_media}`).then((res2) => {
                        oneNew.url = res2.guid.rendered
                        oneNew.url = oneNew.url.replace("http://69.230.196.6:8088", "https://www.bizfocus.cn/wp")
                    });
                });
                setTimeout(()=>{
                   this.box4List.sort((function(a,b) {
                        return a.index-b.index
                    }))
                    this.box4List = this.box4List.slice(0, 5)
                },500)
            }
        },
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        },
        handleJumpTo(type) {
          const URL_MAP = {
            vticket: 'https://visualticket.cn/',
            vproject: 'https://visualticket.cn/',
          }
          if (!type) return;
          const url = URL_MAP[type];
          if (!url) return;
          url && window.open(url, '_blank');
        },
        gotoOutLine(){
            window.location.href = 'http://www.asialink.sh.cn/pc/home.html#'
        },
        next(){
            this.box2arrow = 'right'
            this.box2Index = (this.mySwiper.realIndex+6)%7
        },
        prev(){
            this.box2arrow = 'left'
            this.box2Index = (this.mySwiper.realIndex+1)%7
        },
        next2(){
            this.box2arrow2 = 'right'
            this.box2Index2 = (this.mySwiper2.realIndex+6)%7
        },
        prev2(){
            this.box2arrow2 = 'left'
            this.box2Index2 = (this.mySwiper2.realIndex+1)%7
        },
    },

    watch: {
      productCaseTabValue: {
        handler(newVal) {
          if (newVal === 'productBoutique') {
            setTimeout(() => {
                this.mySwiper.update();
            }, 50);
          } else if (newVal === 'productCase') {
            setTimeout(() => {
                this.mySwiper2.update();
            }, 50);
          }
        },
        immediate: false,
      }
    }
};
</script>

<style lang="less" scoped>
.swiper-slide{
    height: 24.4rem;
    overflow: hidden;
  }
.img-item{
    width: 100%;
    height: 100%;
    .img-container{
        img{
            width: 20.4rem;
            height: 24.4rem;
        }
    }
}
.swiper-slide-active{
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 0px rgba(231,231,231,0.5);
    height: 46rem;
    transition: all 2s;
    .img-container{
        width: 20.4rem;
        height: 24.4rem;
        margin: auto;
        margin-top: 1.7rem;
        overflow: hidden;
    }
    .img-item-img{
        width: 20.4rem;
        height: 24.4rem;
        transition: all 1s;
    }
    .img-item-title{
        /* width: 8rem;
        height: 2rem; */
        text-align: center;
        opacity: 1.0;
        margin: 1.8rem 0 0 0rem;
        font-size: 1.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 2.2rem;
        padding-bottom: 1rem;
        // transition: all 2s;
    }
    .img-item-title:hover{}
    .img-item-content-box{
        width: 100%;
        .img-item-content{
            max-width: 20.3rem;
            min-height: 8rem;
            font-size: 1.6rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #666666;
            line-height: 3rem;

            text-align: center;
            margin: auto;
            margin-top: 0.2rem;
        }
    }

    .img-item-more{
        height: 4rem;
        font-size: 2rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 3rem;

        text-align: center;
        // margin: -1.5rem 0 0 1rem;
        margin-top: 0rem;
        cursor: pointer;
    }
  }
.service-box{
    width: 100%;
    margin: auto;
    margin-top: 2rem;
}
.service-title1{
    display: flex;
    height: 3.9rem;
    font-size: 3.2rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #666666;
    line-height: 3.2rem;
    .service-title2{
        color: #11A84F;
    }
}
.service-title3{
    height: 3.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    text-align: justify;
    font-weight: 400;
    color: #666666;
    line-height: 3.2rem;
    margin-top: 3rem;
    margin-left: 3rem;
}
.service-title4{
    height: 3.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #11A84F;
    line-height: 4.2rem;
    text-align: justify;
    margin-top: 5.4rem;
    margin-left: 3rem;
}
.box-content-title-img {
  width: 20rem;
  height: 7rem;
}
.product-case-tabs {
  padding: 2rem 0 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-case-tab-item {
  font-weight: 500;
  font-size: 2.8rem;
  color: #999999;
  line-height: 3.2rem;
  cursor: pointer;
  padding: 2rem 4rem;
  border-radius: 10rem;
  background-color: #F1F1F1;

  &.active {
    color: #11A84F;
    background-color: #E1F0E7;
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }
}
</style>
